import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import {
  CBox,
  CardImg,
  Centered,
  CardContainer,
  CardColumns,
} from "../../common/styles/emotion";
import "../../common/styles/custom.tachyons.css";
import styled, { css } from "react-emotion";
import { mwS } from "../../common/styles/breakpoints";

const ConsoleLog = ({ children }) => {
  console.log(children);
  return false;
};

const TextBox = styled.div`
  position: absolute;
  bottom: 5%;
  left: 5%;
  width: 60%;
`;

const StyledHeadlineLink = styled(Link)`
  ${mwS.small(css`
    font-size: 1.5em;
  `)};
  ${mwS.medium(css`
    font-size: 2em;
  `)};
  ${mwS.large(css`
    font-size: 2em;
  `)};
  ${mwS.xLarge(css`
    font-size: 2em;
  `)};
`;

const StyledCategory = styled.span`
  color: #ffffff;
  opacity: 1;
  font-family: sans-serif;
  background-color: var(--main-bg-color-dark);
  border: 3px solid var(--main-bg-color-dark);
  border-radius: 6px;

  ${mwS.tallPhone(css`
    font-size: small;
    font-weight: normal;
  `)};
  ${mwS.small(css`
    font-size: small;
    font-weight: bolder;
  `)};
  ${mwS.medium(css`
    font-size: small;
    font-weight: bolder;
  `)};
  ${mwS.large(css`
    font-size: small;
    font-weight: bolder;
  `)};
  ${mwS.xLarge(css`
    font-size: small;
    font-weight: bolder;
  `)};
`;

const StyledDescription = styled.div`
  color: #ffffff;
  background-color: var(--secondary-bg-color);
  border: 6px solid var(--secondary-bg-color);
  border-radius: 6px;
  margin-top: 6px;
  margin-bottom: 6px;
  ${mwS.tallPhone(css`
    font-weight: normal;
    font-size: small;
  `)};
  ${mwS.small(css`
    font-weight: normal;
    font-size: small;
  `)};
  ${mwS.medium(css`
    font-weight: bolder;
    font-size: medium;
  `)};
  ${mwS.large(css`
    font-weight: bolder;
    font-size: medium;
  `)};
  ${mwS.xLarge(css`
    font-weight: bolder;
    font-size: medium;
  `)};
`;

const TextOverImageCard = ({
  cover,
  link,
  title,
  description,
  category,
  row_size,
}) => (
  <CardContainer r_size={row_size}>
    {/* <ConsoleLog> {row_size} </ConsoleLog> */}
    <Link to={link}>
      <CardImg>
        <Img
          fluid={cover.childImageSharp.fluid}
          style={{ height: "100%", objectFit: "cover" }}
        />
      </CardImg>
    </Link>
    <TextBox>
      <StyledHeadlineLink
        className="tracked ttu fw9 white avenir no-underline hover-red"
        to={link}
      >
        {/* <Centered> {title} </Centered> */}
        {title}
      </StyledHeadlineLink>
      <StyledDescription className="highlight-secondary-color avenir">
        {description}
      </StyledDescription>
      <div>
        <StyledCategory className="avenir">{category}</StyledCategory>
      </div>
    </TextBox>
  </CardContainer>
);

export default (props) => {
  var main_post = props.edges[0];
  var secondary_post = props.edges[1];
  var tertiary_post = props.edges[2];

  return (
    <CBox>
      <CardColumns>
        <TextOverImageCard
          cover={main_post.node.frontmatter.postImage}
          link={main_post.node.frontmatter.slug}
          title={main_post.node.frontmatter.title}
          description={main_post.node.frontmatter.description}
          category={main_post.node.frontmatter.category}
          row_size={2}
        />
        <TextOverImageCard
          cover={secondary_post.node.frontmatter.postImage}
          link={secondary_post.node.frontmatter.slug}
          title={secondary_post.node.frontmatter.title}
          description={secondary_post.node.frontmatter.description}
          category={secondary_post.node.frontmatter.category}
          row_size={1}
        />
        <TextOverImageCard
          cover={tertiary_post.node.frontmatter.postImage}
          link={tertiary_post.node.frontmatter.slug}
          title={tertiary_post.node.frontmatter.title}
          description={tertiary_post.node.frontmatter.description}
          category={tertiary_post.node.frontmatter.category}
          row_size={1}
        />

        {/* {data.allMarkdownRemark.edges.map(({ node }) => (
            <TextOverImageCard
              cover={node.frontmatter.postImage}
              link={node.frontmatter.slug}
              title={node.frontmatter.title}
            />
        ))} */}
      </CardColumns>
    </CBox>
  );
};
